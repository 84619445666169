import { useEffect, useState } from 'react';
import { Avatar } from '@mui/material';

import './sidebar-chat.styles.scss';

const SidebarChat = ({ id, name, addNewChat }) => {
	const [seed, setSeed] = useState('');

	useEffect(() => {
		setSeed(Math.floor(Math.random() * 5000));
	}, []);

	const createChat = () => {
		const roomName = prompt('Please enter name for chat');

		if (roomName) {
			// Do some clever database stuff...
		}
	};

	return !addNewChat ? (
		<div className='sidebarChat'>
			<Avatar src={`https://avatars.dicebear.com/api/human/${seed}.svg`} />
			<div className='sidebarChat__info'>
				<h2>{name}</h2>
				<p>Lorem ipsum dolor sit ammet...</p>
			</div>
		</div>
	) : (
		<div className='sidebarChat' onClick={createChat}>
			<h2>Add New Chat</h2>
		</div>
	);
};

export default SidebarChat;
