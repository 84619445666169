// import { useEffect, useState } from 'react';
import { Avatar, IconButton } from '@mui/material';
import ChatIcon from '@mui/icons-material/Chat';
import DonutLargeIcon from '@mui/icons-material/DonutLarge';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import SearchIcon from '@mui/icons-material/Search';
import SidebarChat from '../sidebar-chat/sidebar-chat.component';

import './sidebar.styles.scss';
// import { db } from '../../firebase';

const Sidebar = () => {
	// const [rooms, setRooms] = useState([]);

	// useEffect(() => {
	// 	db.collection('rooms').onSnapshot((snapshot) =>
	// 		setRooms(
	// 			snapshot.docs.map((doc) => ({
	// 				id: doc.id,
	// 				data: doc.data()
	// 			}))
	// 		)
	// 	);
	// }, []);

	return (
		<div className='sidebar'>
			<div className='sidebar__header'>
				<Avatar />
				<div className='sidebar__headerRight'>
					<IconButton>
						<DonutLargeIcon />
					</IconButton>
					<IconButton>
						<ChatIcon />
					</IconButton>
					<IconButton>
						<MoreVertIcon />
					</IconButton>
				</div>
			</div>
			<div className='sidebar__search'>
				<div className='sidebar__searchContainer'>
					<SearchIcon className='searchIcon' />
					<input placeholder='Search or start new chat' type='text' />
				</div>
			</div>
			<div className='sidebar__chats'>
				<SidebarChat addNewChat />
				{/*rooms.map((room) => (
					<SidebarChat key={room.id} id={room.id} name={room.data.name} />
				))*/}
			</div>
		</div>
	);
};

export default Sidebar;
