import { useEffect, useState } from 'react';
import { Avatar, IconButton } from '@mui/material';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import InsertEmoticonIcon from '@mui/icons-material/InsertEmoticon';
import MicIcon from '@mui/icons-material/Mic';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import SearchIcon from '@mui/icons-material/Search';

import './chat.styles.scss';

const Chat = () => {
	const [seed, setSeed] = useState('');
	const [input, setInput] = useState('');

	useEffect(() => {
		setSeed(Math.floor(Math.random() * 5000));
	}, []);

	const setValue = (e) => {
		setInput(e.target.value);
	};

	const sendMessage = (e) => {
		e.preventDefault();
		console.log(input);

		setInput('');
	};

	return (
		<div className='chat'>
			<div className='chat__header'>
				<Avatar src={`https://avatars.dicebear.com/api/human/${seed}.svg`} />
				<div className='chat__headerInfo'>
					<h3>Jane Doe</h3>
					<p>Typing...</p>
				</div>
				<div className='chat__headerRight'>
					<IconButton>
						<SearchIcon />
					</IconButton>
					<IconButton>
						<MoreVertIcon />
					</IconButton>
				</div>
			</div>
			<div className='chat__body'>
				<div className='background'></div>
				<div className='content'>
					<div className={`messages ${false && 'own'}`}>
						<p className={`message ${false && 'own'}`}>
							<span className='text'>Hello!</span>
							<span className='timestamp'>03:11 PM</span>
						</p>
						<p className={`message ${false && 'own'}`}>
							<span className='text'>How are you?</span>
							<span className='timestamp'>03:11 PM</span>
						</p>
					</div>
					<div className={`messages ${true && 'own'}`}>
						<p className={`message ${true && 'own'}`}>
							<span className='text'>Hi!! I'm doing good, how have you been? What's new with you?</span>
							<span className='timestamp'>03:11 PM</span>
						</p>
					</div>
					<div className={`messages ${false && 'own'}`}>
						<p className={`message ${false && 'own'}`}>
							<span className='text'>
								Nothing much, what about you? What have you been up to the past few months?
							</span>
							<span className='timestamp'>03:11 PM</span>
						</p>
					</div>
					<div className={`messages ${true && 'own'}`}>
						<p className={`message ${true && 'own'}`}>
							<span className='text'>
								Oh I've just been doing a bunch of interviews here and there. So far none of them has
								turned into an offer yet. But I'm hoping that one of these days I finally get one, that
								would be really nice.
							</span>
							<span className='timestamp'>03:11 PM</span>
						</p>
						<p className={`message ${true && 'own'}`}>
							<span className='text'>
								I saw a little bit of the party - how was it? Did you have a lot of guests come over?
							</span>
							<span className='timestamp'>03:11 PM</span>
						</p>
					</div>
				</div>
			</div>
			<div className='chat__footer'>
				<IconButton>
					<InsertEmoticonIcon />
				</IconButton>
				<IconButton>
					<AttachFileIcon />
				</IconButton>
				<form>
					<input type='text' placeholder='Send a message' value={input} onChange={setValue} />
					<button type='submit' onClick={sendMessage}>
						Send a message
					</button>
				</form>
				<IconButton>
					<MicIcon />
				</IconButton>
			</div>
		</div>
	);
};

export default Chat;
